
export const defaultBackorderServices = [
    {
        displayName: "NameJet",
        slug: "namejet",
        active: true
    },
    {
        displayName: "GoDaddy Auctions",
        slug: "godaddy",
        active: true
    },
    {
        displayName: "SnapNames",
        slug: "snapnames",
        active: true
    }
]
